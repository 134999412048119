import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const callFetch = createAsyncThunk('concern/fetch', async (hash) => {
    const url = "https://sensors.us-east-2.senseandprotect.com/red/:id".replace(':id', hash);
    const response = await fetch(url, {
        method : 'get',
    })
    return await response.json();
});

export const callAcknowledge = createAsyncThunk('concern/acknowledge', async (hash) => {
    const url =  "https://sensors.us-east-2.senseandprotect.com/red/:id/acknowledge".replace(':id', hash);
    const response = await fetch(url, {
        method : 'post',
    })
    return await response.json();
});


export const concernSlice = createSlice({
    name: 'concern',
    initialState: {
        isLoading : false,
        isLoaded : false,
        isError : false,
        concernData : {
            id : '',
            name : '',
            baseId : '',
            baseName : '',
            sensorId : '',
            sensorName : '',
            acknowledgedByName : "",
            expression : "",
            escalationPolicyName : "",

            concernTableHeader : [],
            concernTableRows : [ ],
            sensorTableHeader : [],
            sensorTableRows : [ ]

        }
    },
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(callFetch.pending, (state, action) => {
                state.isError = false;
                state.isLoading = true;
            })
            .addCase(callFetch.fulfilled, (state, action) => {
                state.isError = false;
                state.isLoading = false;
                const payload = action?.payload;

                if(payload && ! payload.hasOwnProperty('error')) {
                    state.concernData = action?.payload;
                    state.isLoaded = true;
                }
                else {
                    state.isError = true;
                }
            })
            .addCase(callFetch.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
            });

        builder
            .addCase(callAcknowledge.pending, (state, action) => {
                state.isError = false;
                state.isLoading = true;
            })
            .addCase(callAcknowledge.fulfilled, (state, action) => {
                state.isError = false;
                state.isLoading = false;

                const newConcernData = { ...state.concernData };
                newConcernData.acknowledgedByName = action.payload?.acknowledgedByName;

                state.concernData = newConcernData;
            })
            .addCase(callAcknowledge.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
            });
    }
})

export const getIsLoading = (state) => state.concern.isLoading;
export const getIsLoaded = (state) => state.concern.isLoaded;
export const getIsError = (state) => state.concern.isError;
export const getConcernData = (state) => state.concern.concernData;
export default concernSlice.reducer