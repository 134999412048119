import * as React from 'react';
import { Button, Icon, NonIdealState, Spinner} from "@blueprintjs/core";
import {useDispatch, useSelector} from "react-redux";
import {
    callAcknowledge,
    getIsLoading,
    getIsLoaded,
    getIsError,
    getConcernData,
    callFetch
} from "./feature/concern/concernSlice";
import {useEffect} from "react";


export default function AppContainer() {
    const isLoading = useSelector(getIsLoading);
    const isLoaded = useSelector(getIsLoaded);
    const isError = useSelector(getIsError);
    const concernData = useSelector(getConcernData);
    const dispatch = useDispatch();

    const hash = (new URLSearchParams(document.location.search)).toString().replace('=', '');

    useEffect(() => {
        if(! isLoaded && !isError && hash) {
            dispatch(callFetch(hash));
        }
    }, [dispatch, isLoaded, isError, hash]);

    const acknowledgeConcern = (e) => {
        dispatch(callAcknowledge(hash));
        e.preventDefault();
    };

    const concernAcknowledgement = (x) => {
        if(! x?.isConcerning) {
            const description = "This concern has been resolved.";
            return <NonIdealState
                icon="issue-closed"
                iconSize="64"
                title="Concern Resolved"
                description={description}
                layout="vertical"
            />
        }


        if(x?.acknowledgedByName) {
            const description = "This concern has been acknowledged by " + x?.acknowledgedByName;
            return <NonIdealState
                icon="tick"
                iconSize="64"
                title="Concern Acknowledged"
                description={description}
                layout="vertical"
            />
        }

        const acknowledgeButton = <Button icon="tick" onClick={acknowledgeConcern}>Acknowledge Concern</Button>;
        return <NonIdealState
            icon="id-number"
            iconSize="64"
            title="Unacknowledged Concern"
            description="This concern has not been acknowledged yet. Take ownership to prevent further escalations."
            action={acknowledgeButton}
            layout="vertical"
        />
    };


    if(isLoading) {
        return <div className="app-container">
            <div className="app-container-inner">
                <div className="app-container-non-ideal">
                    <NonIdealState
                        icon={ <Spinner size="64" /> }
                        iconSize="64"
                        title="Loading..."
                        description="Loading data, please wait..."
                        layout="vertical"
                    />
                </div>
            </div>
        </div>;
    }

    if(isError || ! isLoaded) {
        return <div className="app-container">
            <div className="app-container-inner">
                <div className="app-container-non-ideal">
                <NonIdealState
                    icon="error"
                    iconSize="64"
                    title="Error..."
                    description="We were unable to load data, please try again later."
                    layout="vertical"
                />
                </div>
            </div>
        </div>;
    }

    let className = 'app-container ';
    if(isLoaded) {
        className += concernData?.isConcerning ? 'state-concern' : 'state-clear';
    }

    return (
        <div className={className}>
            <div className="app-container-inner">
                <div className="app-container-header">
                    <h3 className="bp5-heading">{ concernData?.name }</h3>
                    <p className="bp5-text-large"><Icon icon="layers" /> { concernData?.baseName } &nbsp;&nbsp;&nbsp; <Icon icon="sensor" /> { concernData?.sensorName }</p>
                    <p>{ concernData?.expression }</p>
                    { concernData?.escalationPolicyName ? <p>Escalation policy: { concernData?.escalation }</p> : ''}
                </div>

                <div className="app-container-content">
                    <div className="grid-area-concern">
                        <h4 className="bp5-heading">Concern History</h4>
                        <table className="bp5-html-table bp5-compact">
                            <thead>
                            <tr>
                                { concernData.concernTableHeader.map((item, index) => <th key={index}>{item}</th>)}
                            </tr>
                            </thead>
                            <tbody>
                            { concernData.concernTableRows.map((row, index) => <tr key={index}>{ row.map((item, j) => <td key={j + ':' + index}>{item}</td>)}</tr>)}
                            </tbody>
                        </table>
                    </div>
                    <div className="grid-area-s1"></div>
                    <div className="grid-area-data">
                        <h4 className="bp5-heading">Recent Data</h4>
                        <table className="bp5-html-table bp5-compact">
                            <thead>
                            <tr>
                                { concernData.sensorTableHeader.map((item, index) => <th key={index}>{item}</th>)}
                            </tr>
                            </thead>
                            <tbody>
                            { concernData.sensorTableRows.map((row, index) => <tr key={index}>{ row.map((item, j) => <td key={j + ':' + index}>{item}</td>)}</tr>)}
                            </tbody>
                        </table>
                    </div>
                    <div className="grid-area-s2"></div>
                    <div className="grid-area-acknowledgement">
                        {concernAcknowledgement(concernData)}
                    </div>

                </div>

            </div>
        </div>
    );
}
